import Link from 'next/link'
import clsx from 'clsx'

import { IconForward } from 'icons'

import { IBigLinkProps } from './types'

const BigLink = ({
  buttonField,
  hrefDefault,
  buttonText,
  onClick,
  className
}: IBigLinkProps) => {
  return (
    <Link
      onClick={onClick}
      href={buttonField?.uid ?? hrefDefault}
      className={clsx(
        'flex h-[68px] w-full flex-none items-center justify-between border-[2px] border-black bg-white sm:min-w-[320px]',
        'font-semibold uppercase transition-colors hover:border-red-550 hover:bg-red-550 hover:text-white active:border-red-600 active:bg-red-600 active:text-white',
        className
      )}
    >
      <div className="flex-1 px-5">{buttonText}</div>
      <div className="fade-in flex h-full flex-none items-center justify-center border-l-[2px] border-l-inherit bg-red-550 px-4">
        <IconForward big />
      </div>
    </Link>
  )
}

export default BigLink
