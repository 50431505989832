import PricingPlan from 'components/PricingPlan'
import { usePricingContext } from 'components/PricingPlanSection/PricingContext'

import { PricingBulletsSlice } from 'types/prismic'

const PrismicPricingPlan = ({ slice }: { slice: PricingBulletsSlice }) => {
  const prices = usePricingContext()

  return (
    <PricingPlan
      time={prices.time}
      price={prices[slice.primary.planType]}
      planInfo={slice}
      active
    />
  )
}

export default PrismicPricingPlan
