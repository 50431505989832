import { z } from 'zod'

const promotionValidator = z.object({
  name: z.string().min(2).max(255),
  email: z.string().min(1).email(),
  phoneNumber: z.string().min(1).max(13)
})

export type IPromotionValidator = z.infer<typeof promotionValidator>

export default promotionValidator
