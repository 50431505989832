import { useState } from 'react'
import { asLink } from '@prismicio/helpers'
import clsx from 'clsx'

import { Bounded, BoundedVariant } from 'components/Bounded'
import { Button } from 'components/buttons'
import { IconArrow } from 'icons'

import {
  PricingSectionSlice,
  PricingSectionSliceDefaultItem
} from 'types/prismic'
import { linkResolver } from 'env/prismicio'

interface PriceLineProps extends PricingSectionSliceDefaultItem {
  isIndividual: boolean
}
const PriceLine = (props: PriceLineProps) => {
  return (
    <div
      className={clsx(
        'fade-in boder-black my-1 flex w-full flex-col gap-y-2 border-b-2 border-black py-4 last:border-b-0 md:flex-row md:items-start',
        (props.Type?.toLowerCase() === 'company' && props.isIndividual) ||
          (props.Type?.toLowerCase() != 'company' && !props.isIndividual)
          ? 'hidden'
          : ''
      )}
    >
      <div className="flex-[2] pr-10 text-3xl font-semibold">{props.title}</div>
      <div className="flex-[5] text-lg">{props.description}</div>
      <div className="mt-2 flex flex-row flex-wrap items-center justify-between gap-5">
        <div className="text-4xl font-semibold md:px-10">{props.price}</div>
        {props.button && (
          <Button
            href={asLink(props.button, linkResolver)}
            button
            className="animated-svg"
          >
            <span>{props.buttonText}</span>
            <span className="ml-2">
              <IconArrow />
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

const PricingSection = ({ slice }: { slice: PricingSectionSlice }) => {
  const [isIndividual, setIsIndividual] = useState(true)
  return (
    <Bounded
      as="section"
      variant={BoundedVariant.Base}
      collapsible={false}
      className="w-full bg-white pb-10"
    >
      <div className="mb-10 flex w-full flex-col justify-between gap-y-10 md:flex-row md:items-end">
        <div className="flex-1">
          <h3 className="text-2xl font-bold md:text-5xl">
            {slice.primary.title}
            <i className="dot"></i>
          </h3>
          <h5>{slice.primary.subtitle}</h5>
        </div>
        <div className="flex w-full items-center justify-center md:w-auto">
          <button
            onClick={() => setIsIndividual(true)}
            className={clsx(
              'sm:font-lg mx-2 px-5 font-semibold uppercase transition-colors hover:text-red-550 active:text-red-600',
              isIndividual && 'text-red-550'
            )}
          >
            {slice.primary.forIndividuals}
          </button>
          <button
            onClick={() => setIsIndividual(false)}
            className={clsx(
              'sm:font-lg mx-2 px-5 font-semibold uppercase transition-colors hover:text-red-550 active:text-red-600',
              !isIndividual && 'text-red-550'
            )}
          >
            {slice.primary.forCompanies}
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        {slice.items
          .filter(
            (item) =>
              (item.Type?.toLowerCase() === 'company' && !isIndividual) ||
              (item.Type?.toLowerCase() !== 'company' && isIndividual)
          )
          .map((item) => (
            <PriceLine
              key={`${item.Type}_${item.title}`}
              {...item}
              isIndividual={isIndividual}
            />
          ))}
      </div>
    </Bounded>
  )
}

export default PricingSection
