import { PrismicRichText } from '@prismicio/react'
import clsx from 'clsx'

import { TextListSlice } from 'types/prismic'

import styles from 'styles/Texts.module.scss'

const TextList = ({ slice }: { slice: TextListSlice }) => {
  return (
    <div
      className={clsx(
        styles.list,
        '!mt-0',
        '[&_a]:underline [&_a]:transition-opacity hover:[&_a]:opacity-80 active:[&_a]:opacity-80'
      )}
    >
      <ul>
        <ul>
          {slice.items.map((item, index) => (
            <li key={index}>
              {<PrismicRichText field={item.listitem} />}
              {item.subitem && <PrismicRichText field={item.subitem} />}
            </li>
          ))}
        </ul>
      </ul>
    </div>
  )
}
export default TextList
