import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Bounded, BoundedVariant } from 'components/Bounded'

import { newsSubscription } from 'hooks/useNewsSubscription'

import subscriptionFormValidator from 'validators/subscriptionValidator'
import { NewsletterSlice } from 'types/prismic'
import { handleQueryError } from 'utils/queryErrorHandler'

const useNewsLetter = () => {
  const [messageSent, setMessageSent] = useState(false)

  const { mutate, isLoading } = useMutation(newsSubscription, {
    onSuccess: () => {
      setMessageSent(true)
    },
    onError: handleQueryError
  })

  return {
    messageSent,
    mutate,
    isLoading
  }
}

const Newsletter = ({ slice }: { slice: NewsletterSlice }) => {
  const { t } = useTranslation(['actions'])
  const { mutate, messageSent } = useNewsLetter()
  return (
    <Bounded
      as="section"
      variant={BoundedVariant.Base}
      collapsible={false}
      className="mb-2 bg-indigo-1000"
    >
      <h4 className="mb-20 text-2xl font-bold text-white md:text-5xl">
        {slice.primary.title}
        <i className="dot"></i>
      </h4>
      {messageSent && (
        <p className="text-xl text-red-500">{t('toast.sentInfo')}</p>
      )}

      <Formik
        enableReinitialize
        initialValues={{
          email: ''
        }}
        onSubmit={async (payload, { resetForm }) => {
          if (!messageSent) {
            mutate(payload)
            resetForm()
          }
        }}
        validationSchema={toFormikValidationSchema(subscriptionFormValidator)}
      >
        {({ values, errors, handleChange }) => (
          <Form>
            <div className="flex flex-col gap-x-10 gap-y-4 md:flex-row">
              <div className="h-[64px] w-full flex-1 text-xl">
                <input
                  onChange={handleChange}
                  name="email"
                  className="block h-[64px] w-full  border-0 border-b-2 border-white bg-indigo-1000 text-white transition-colors focus:border-red-550"
                  type="email"
                  placeholder={slice.primary.placeholder ?? ''}
                />
                {errors.email && values.email && (
                  <span className="text-sm text-red-500">{errors.email}</span>
                )}
              </div>
              <button
                className="mr-0 ml-auto flex h-[64px] w-[280px] items-center justify-between border-2 border-white uppercase text-white transition-colors hover:bg-white hover:text-black"
                type="submit"
              >
                <span className="py-5 pl-5 font-medium tracking-wide">
                  {slice.primary.buttonText}
                </span>
                <span className="flex h-full items-center justify-center bg-white px-5 text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Bounded>
  )
}

export default Newsletter
