import { useSession } from 'next-auth/react'
import { PrismicRichText } from '@prismicio/react'

import BigLink from 'components/BigLink'
import { IconUser } from 'icons'

import { NotFoundSlice } from 'types/prismic'
import { UserRole } from 'constants/auth'

const NotFound = ({ slice }: { slice: NotFoundSlice }) => {
  const { data } = useSession()

  return (
    <section className="flex flex-col items-center ">
      <h1 className="my-2 text-4xl font-bold">{slice.primary.title}</h1>
      <div className="text-center font-medium leading-[115%]">
        <PrismicRichText field={slice.primary.description} />
      </div>
      <div>
        <BigLink
          hrefDefault={
            !data?.user
              ? '/sign-in'
              : data.user.role === UserRole.Candidate
                ? slice?.primary?.backPathCandidate ?? '/'
                : slice?.primary?.backPathOrg ?? '/'
          }
          buttonText={
            <span className="flex items-center gap-2">
              <span className="h-4 w-4">
                <IconUser />
              </span>
              <span>
                {!data?.user
                  ? slice?.primary?.buttonTextUnSignedIn
                  : slice?.primary?.buttonTextLoggedIn}
              </span>
            </span>
          }
          className="my-8"
        />
      </div>
    </section>
  )
}

export default NotFound
