import { PrismicLink } from '@prismicio/react'

import { Bounded, BoundedVariant } from 'components/Bounded'
import { IconArrow } from 'icons'

import { CtaSectionSlice } from 'types/prismic'

const CtaSection = ({ slice }: { slice: CtaSectionSlice }) => (
  <Bounded
    as="section"
    variant={BoundedVariant.Base}
    collapsible={false}
    className="mb-2 bg-indigo-1000"
  >
    <div className="flex flex-wrap items-center justify-between gap-y-10">
      <h4 className="font-medium text-white">
        <span className="text-[60px] font-bold leading-tight sm:text-[80px] md:text-[100px] lg:text-[120px]">
          {slice.primary.title}
        </span>
        <br />
        {slice.primary.subtitle}
        <i className="dot"></i>
      </h4>
      <PrismicLink
        field={slice.primary.button}
        className="animated-svg mr-0 ml-auto flex h-[64px] w-[280px] items-center justify-between border-2 border-white uppercase text-white transition-colors hover:bg-white hover:text-black"
      >
        <span className="py-5 pl-5 font-medium tracking-wide">
          {slice.primary.buttonText}
        </span>
        <span className="flex h-full items-center justify-center bg-white px-5 text-black">
          <IconArrow />
        </span>
      </PrismicLink>
    </div>
  </Bounded>
)

export default CtaSection
