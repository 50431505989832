import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button } from 'components/buttons'
import Input from 'components/Fields/Input'
import { IconArrow } from 'icons'

import promotionValidator from 'validators/promotionValidator'

import { IFormUsePromotionProps } from './types'

const FormUsePromotion = ({
  isLoading,
  className,
  onSubmit
}: IFormUsePromotionProps) => {
  const { data: session } = useSession()
  const user = session?.user

  const { t } = useTranslation(['inputs', 'actions'])

  return (
    <Formik
      enableReinitialize
      validationSchema={toFormikValidationSchema(promotionValidator)}
      onSubmit={onSubmit}
      initialValues={{
        name: user?.fullName ?? '',
        email: user?.email ?? '',
        phoneNumber: ''
      }}
    >
      <Form className={className}>
        <Input
          name="name"
          label={t('name.label', { ns: 'inputs' })}
          placeholder={t('name.placeholder', { ns: 'inputs' })}
          required
        />
        <Input
          name="email"
          label={t('email.label', { ns: 'inputs' })}
          placeholder={t('email.placeholder', { ns: 'inputs' })}
          type="email"
          required
        />
        <Input
          name="phoneNumber"
          label={t('phoneNumber.label', { ns: 'inputs' })}
          placeholder={t('phoneNumber.placeholder', { ns: 'inputs' })}
          required
        />
        <div className=" mt-5 flex items-center justify-end gap-5">
          <Button
            type="submit"
            className="animated-svg"
            isLoading={isLoading}
            disabled={isLoading}
          >
            <span className="mr-2">{t('send', { ns: 'actions' })}</span>
            <IconArrow />
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

export default FormUsePromotion
