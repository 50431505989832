import { publicClient } from 'utils'
import { getFromClient, setFromClient } from 'utils/cookies'
import { supportAddressConstant } from 'constants/email'

const cookieName = 'sent-subscription-count'
const limitMessages = 5

export const newsSubscription = async ({ email }: { email: string }) => {
  const sentMessages = Number(getFromClient(cookieName)) ?? 0
  if (sentMessages >= limitMessages) {
    throw Error('You can not sent message')
  }

  const { data } = await publicClient.post(`/mailing/send`, {
    sendTo: supportAddressConstant,
    subject: 'Newsletter subscription',
    plainTextContent: `Email: ${email}`,
    htmlContent: `Email: ${email}`
  })
  setFromClient(cookieName, (sentMessages + 1).toString())
  return data
}
