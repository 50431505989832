import dynamic from 'next/dynamic'

import { InteractiveMapSlice } from 'types/prismic'
import { AppEnv } from 'utils/env/env.type'
import { getEnv } from 'utils/env/env.util'
const InteractiveMapComponent = dynamic(
  () => import('components/InteractiveMap'),
  {
    ssr: false
  }
)

type RenderType = 'Both' | 'Production' | 'Testing'
const mapRender: Record<RenderType, AppEnv | null> = {
  Both: null,
  Production: 'production',
  Testing: 'staging'
}

const InteractiveMap = ({ slice }: { slice: InteractiveMapSlice }) => {
  const { appEnv } = getEnv()

  if (
    mapRender[slice.primary.render] &&
    mapRender[slice.primary.render] !== appEnv
  ) {
    return null
  }

  return <InteractiveMapComponent className="pb-5" />
}

export default InteractiveMap
