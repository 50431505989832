import { useState } from 'react'
import clsx from 'clsx'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'

import AdvertCard from 'components/AdvertCard'
import { SwiperButton } from 'components/buttons'

import { RESPONSIVE } from 'constants/responsive.constant'

import 'swiper/css/pagination'

import { IAdvertSwiperProps } from './types'

import 'swiper/css'

export const AdvertSwiper = ({
  adverts,
  className,
  isNextLoading,
  onEnd
}: IAdvertSwiperProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)

  if (!adverts.length) return null

  return (
    <div className="relative mx-auto max-w-[96vw] py-10">
      <Swiper
        onSwiper={setSwiper}
        onSlideChange={(swiper) => {
          if (swiper.isEnd) {
            onEnd?.()
          }
        }}
        spaceBetween={50}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          [RESPONSIVE.md]: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          [RESPONSIVE.lg]: {
            slidesPerView: 3,
            spaceBetween: 50
          }
        }}
        autoHeight={true}
        className="md:!px-[50px]"
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 12
        }}
        modules={[Pagination, Autoplay]}
        autoplay
      >
        {adverts.length > 3 && (
          <SwiperButton
            className={clsx(
              'hidden bg-white md:flex md:pr-1 md:pl-0',
              className
            )}
            type={'prev'}
          />
        )}
        {adverts?.map((ad) => (
          <SwiperSlide
            key={ad.id}
            className="fade-in-delay pb-10"
            style={{ width: '300px', position: 'inherit' }}
          >
            <div
              onMouseEnter={() => swiper?.autoplay.stop()}
              onMouseLeave={() => swiper?.autoplay.start()}
            >
              <AdvertCard ad={ad} className="!border-black" swiper={swiper} />
            </div>
          </SwiperSlide>
        ))}
        {adverts.length > 3 && (
          <SwiperButton
            isLoading={isNextLoading}
            className={clsx(
              'hidden bg-white md:flex  md:pl-2 md:pr-0',
              className
            )}
            type={'next'}
          />
        )}
      </Swiper>
    </div>
  )
}

export default AdvertSwiper
