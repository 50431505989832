import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { PrismicRichText } from '@prismicio/react'

import AdvertsPage from 'components/AdvertsPage'
import { IBadge, IContext } from 'components/AdvertsPage/types'

import { AdvertsSearchSectionSlice } from 'types/prismic'

import Title from '../../components/Title'

const AdvertsSearchSection = ({
  slice,
  context
}: {
  slice: AdvertsSearchSectionSlice
  context: IContext
}) => {
  const { t } = useTranslation(['home_adverts'])
  const { query } = useRouter()

  const sliceItem = useMemo(
    () => slice.items.find((item) => item.cityvalue == query.subpage),
    [query.subpage, slice.items]
  )

  const badges = useMemo(() => {
    return slice.items.map((item) => {
      return {
        citybadge: item.citybadge,
        cityvalue: item.cityvalue
      } as unknown as IBadge
    })
  }, [slice.items])

  return (
    <div>
      <Title variant={'h1'} className={'mt-10 mb-5 px-2 lg:px-0'}>
        <Trans
          i18nKey={sliceItem?.cityvalue ? 'badges.city' : 'jobAdsTitle'}
          t={t}
          ns={'home_adverts'}
          values={{ value: sliceItem?.citybadge }}
          components={{ text: <></> }}
        />
      </Title>

      <div
        className={
          'mb-5 px-2 lg:px-0 [&_a]:font-semibold [&_a]:underline [&_a]:transition-colors hover:[&_a]:text-red-550'
        }
      >
        <PrismicRichText field={sliceItem?.toptext} />
      </div>

      <AdvertsPage initialCities={badges} {...context} />

      <div
        className={
          'mb-10 px-2 lg:px-0 [&_a]:font-semibold [&_a]:underline [&_a]:transition-colors hover:[&_a]:text-red-550'
        }
      >
        <PrismicRichText field={sliceItem?.bottomtext} />
      </div>
    </div>
  )
}

export default AdvertsSearchSection
