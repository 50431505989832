import * as prismicH from '@prismicio/helpers'
import { PrismicText } from '@prismicio/react'

import { CommunityQouteSlice } from 'types/prismic'

import '@fontsource/poppins/600-italic.css'

const CommunityQoute = ({ slice }: { slice: CommunityQouteSlice }) => (
  <section className="border-l-4 border-red-550 pl-12 [&_a]:font-semibold [&_a]:underline [&_a]:transition-colors hover:[&_a]:text-red-550">
    {prismicH.isFilled.richText(slice.primary.quote) && (
      <div className=" text-2xl font-semibold italic leading-relaxed">
        <PrismicText field={slice.primary.quote} />
        {prismicH.isFilled.keyText(slice.primary.source) && (
          <> &mdash; {slice.primary.source}</>
        )}
      </div>
    )}
  </section>
)

export default CommunityQoute
