import { createContext, useContext } from 'react'

import { times } from 'constants/subscription'

import { ISubscriptionsPrice } from './types'

export const PricingContext = createContext<ISubscriptionsPrice>({
  trial: 0,
  time: times[1],
  basic: 0,
  pro: 0
})
export const usePricingContext = () => useContext(PricingContext)
