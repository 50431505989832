import { ElementType } from 'react'
import clsx from 'clsx'

export const Bounded = ({
  as: Comp = 'div',
  size = 'none',
  className,
  children
}: {
  as?: ElementType
  size?: string
  className?: string
  children: JSX.Element[] | JSX.Element | string | null
}) => {
  return (
    <Comp className={clsx('px-4 py-4 md:px-6', className)}>
      <div
        className={clsx(
          'mx-auto w-full',
          size === 'small' && 'max-w-xl',
          size === 'base' && 'max-w-3xl',
          size === 'wide' && 'max-w-4xl',
          size === 'widest' && 'max-w-6xl'
        )}
      >
        {children}
      </div>
    </Comp>
  )
}
