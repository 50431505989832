import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import { Bounded } from 'components/BoundedCommunity'

import { CommunityTextSlice } from 'types/prismic'

const CommunityText = ({ slice }: { slice: CommunityTextSlice }) => (
  <Bounded as="section" className="mb-0 py-2">
    <div>
      {slice.primary.title && (
        <h4 className="mb-5 text-2xl font-semibold">
          {slice.primary.title}
          <i className="dot" />
        </h4>
      )}
      {prismicH.isFilled.richText(slice.primary.text) && (
        <div className="paragraph-margin anchors:text-red-550 text-sm leading-relaxed text-indigo-1000 md:leading-relaxed [&_a]:font-semibold [&_a]:underline [&_a]:transition-colors hover:[&_a]:text-red-550">
          <PrismicRichText field={slice.primary.text} />
        </div>
      )}
    </div>
  </Bounded>
)

export default CommunityText
