import * as prismicH from '@prismicio/helpers'
import { PrismicNextImage } from '@prismicio/next'
import { PrismicRichText } from '@prismicio/react'

import { Bounded } from 'components/BoundedCommunity'

import { CommunityImageSlice } from 'types/prismic'

import '@fontsource/poppins/500-italic.css'

const CommunityImage = ({ slice }: { slice: CommunityImageSlice }) => (
  <Bounded
    as="section"
    size={slice.variation === 'wide' ? 'widest' : 'base'}
    className="py-3 pb-0"
  >
    <figure className="grid grid-cols-1 gap-2">
      {prismicH.isFilled.image(slice.primary.image) && (
        <div className="bg-gray-100">
          <PrismicNextImage field={slice.primary.image} layout="responsive" />
        </div>
      )}
      {prismicH.isFilled.richText(slice.primary.caption) && (
        <figcaption className="text-right text-sm italic tracking-tight text-slate-500">
          <PrismicRichText field={slice.primary.caption} />
        </figcaption>
      )}
    </figure>
  </Bounded>
)

export default CommunityImage
