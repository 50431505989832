import React from 'react'

import { Bounded } from 'components/Bounded'

import { SectionTitleSlice } from 'types/prismic'

const SectionTitle = ({ slice }: { slice: SectionTitleSlice }) => (
  <Bounded>
    <h1 className="px-5 pb-16 pt-20 text-3xl font-bold sm:text-5xl xl:px-0">
      {slice.primary.title}
      <i className="dot" />
    </h1>
  </Bounded>
)

export default SectionTitle
