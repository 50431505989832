// import Image from 'next/image'
import Image from 'next/image'
import Link from 'next/link'
import clsx from 'clsx'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Bounded } from 'components/Bounded'

import { AdvertisementBannerSlice } from 'types/prismic'

const AdSlice = ({ slice }: { slice: AdvertisementBannerSlice }) => (
  <Bounded
    as="section"
    collapsible={false}
    className={clsx(
      'my-4 py-8 md:py-12',
      slice.primary.isDark
        ? 'mb-16 bg-indigo-1000 text-white'
        : 'bg-white text-black'
    )}
  >
    <div className="flex w-full flex-col px-5 lg:flex-row lg:items-center">
      <div>
        {slice.primary.Title && (
          <h3 className="mb-3 text-2xl font-bold md:text-5xl">
            {slice.primary.Title}
            <i className="dot"></i>
          </h3>
        )}
        {slice.primary.subtitle && (
          <h4 className="mt-1 text-gray-400">{slice.primary.subtitle}</h4>
        )}
      </div>
    </div>
    <div className="relative mx-auto max-w-[96vw] py-5">
      <Swiper
        spaceBetween={20}
        // loop
        modules={[Autoplay]}
        autoplay
      >
        {slice.items.map((item) => (
          <SwiperSlide key={item.banner.url}>
            <Link href={(item.bannerLink as any)?.url ?? '#'}>
              <div className="relative my-5 aspect-[6/1] w-full">
                {item.banner && (
                  <Image
                    src={item.banner.url as string}
                    alt={item.banner.alt + ' ad'}
                    fill
                    style={{
                      objectFit: 'contain'
                    }}
                  />
                )}
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </Bounded>
)

export default AdSlice
