import Image from 'next/image'
import { PrismicRichText } from '@prismicio/react'
import clsx from 'clsx'

import { Bounded } from 'components/Bounded'

import { SectionParagraphSlice } from 'types/prismic'

const SectionParagraph = ({ slice }: { slice: SectionParagraphSlice }) => {
  return (
    <Bounded>
      <section
        className={clsx(
          slice.primary.sectionId === 'short-description'
            ? 'pb-5'
            : 'relative flex px-5 pb-12 xl:px-0'
        )}
      >
        {slice.primary.sectionId && (
          <div
            className="absolute -top-20 left-0"
            id={slice.primary.sectionId}
          />
        )}
        {slice.primary.titleIcon.url && (
          <div className="relative mr-10 h-10 w-10">
            <Image
              src={slice.primary.titleIcon.url}
              alt={slice.primary.titleIcon.alt ?? ''}
              style={{ objectFit: 'contain' }}
              fill
            />
          </div>
        )}
        <div className="flex-1">
          {slice.primary.title && (
            <h2 className="pb-6 text-xl font-semibold sm:text-3xl">
              {slice.primary.title}
            </h2>
          )}
          <div className="br-margin text-lg text-gray-600">
            {slice.primary.text && (
              <PrismicRichText field={slice.primary.text} />
            )}
          </div>
        </div>
      </section>
    </Bounded>
  )
}

export default SectionParagraph
