import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button } from 'components/buttons'
import Input from 'components/Fields/Input'
import TextArea from 'components/Fields/TextArea'
import { IconArrow } from 'icons'

import { postSendMessage } from 'hooks/support/support.service'

import contactUsValidator from 'validators/contactUsValidation'
import { handleQueryError } from 'utils/queryErrorHandler'

const FormContactUs = () => {
  const { data: session } = useSession()
  const user = session?.user
  const { t } = useTranslation(['about_us'])
  const { mutate, isLoading } = useMutation(postSendMessage, {
    onSuccess: () => {
      toast.success(t('messageSuccessfullySent', { ns: 'about_us' }))
    },
    onError: handleQueryError
  })

  return (
    <Formik
      enableReinitialize
      validationSchema={toFormikValidationSchema(contactUsValidator)}
      onSubmit={async (payload, { resetForm }) => {
        mutate(payload)
        resetForm()
      }}
      initialValues={{
        name: user?.fullName ?? '',
        email: user?.email ?? '',
        message: ''
      }}
    >
      {({ values }) => (
        <Form className="w-full">
          <Input
            name="name"
            label={t('name', { ns: 'about_us' })}
            required
            placeholder={t('yourName', { ns: 'about_us' })}
          />
          <Input
            name="email"
            label={t('emailAddress', { ns: 'about_us' })}
            placeholder="example@hrizer.com"
            type="email"
            required
          />
          <TextArea
            name="message"
            label={t('message', { ns: 'about_us' })}
            required
            maxlength={700}
            placeholder={`${t('messageTypingHere')}...`}
            countSymbols={700 - values.message.length}
          />
          <div className=" mt-5 flex items-center justify-end gap-5">
            <Button
              type="submit"
              className="animated-svg"
              isLoading={isLoading}
              disabled={isLoading}
            >
              <span className="mr-2">{t('send', { ns: 'about_us' })}</span>
              <IconArrow />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FormContactUs
