import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import { Bounded } from 'components/BoundedCommunity'

import { CommunityBulletSlice } from 'types/prismic'

const CommunityBullet = ({ slice }: { slice: CommunityBulletSlice }) => (
  <Bounded
    as="section"
    className="mb-5 bg-gray-50 [&_a]:font-semibold [&_a]:underline [&_a]:transition-colors hover:[&_a]:text-red-550"
  >
    <div className="px-5">
      {slice.primary.title && (
        <h4 className="mb-5 text-2xl font-semibold">
          {slice.primary.title}
          <i className="dot" />
        </h4>
      )}
      {prismicH.isFilled.richText(slice.primary.text) && (
        <div className="paragraph-margin mb-4 text-sm leading-relaxed text-indigo-1000 md:leading-relaxed">
          <PrismicRichText field={slice.primary.text} />
        </div>
      )}
      <ul
        role="list"
        className="list-disc pl-3 marker:border-2 marker:text-[1.25em] marker:text-red-550"
      >
        {slice.items.map((item, index) => (
          <li
            key={index}
            className="paragraph-margin mb-3 pl-2 text-sm leading-relaxed text-indigo-1000 md:leading-relaxed"
          >
            <PrismicRichText field={item.bullet} />
          </li>
        ))}
      </ul>
    </div>
  </Bounded>
)

export default CommunityBullet
