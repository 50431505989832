import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'

import { IFormUsePromotionProps } from 'components/Forms/FormUsePromotion/types'

import { sendUsePromotionMail } from 'hooks/support/support.service'

import { handleQueryError } from 'utils/queryErrorHandler'

const usePromoOfferResponse = ({ mailTo }: { mailTo: string | null }) => {
  const { t } = useTranslation(['shares'])

  const { mutate, isLoading } = useMutation(sendUsePromotionMail, {
    onSuccess: () => {
      toast.success(t('messageSuccessfullySent', { ns: 'shares' }))
    },
    onError: handleQueryError
  })

  const onSubmit = useCallback<IFormUsePromotionProps['onSubmit']>(
    (values, { resetForm }) => {
      mutate({ ...values, mailTo })

      resetForm()
    },
    [mailTo, mutate]
  )

  return { isLoading, onSubmit }
}

export default usePromoOfferResponse
