import { asText } from '@prismicio/helpers'

import FormUsePromotion from 'components/Forms/FormUsePromotion'
import Title from 'components/Title'

import { PromotionOfferSlice } from 'types/prismic'

import usePromoOfferResponse from './usePromotionOffer'

const PromotionOffer = ({ slice }: { slice: PromotionOfferSlice }) => {
  const { isLoading, onSubmit } = usePromoOfferResponse({
    mailTo: slice.primary.email
  })
  return (
    <div className="max-w-xl">
      <div className="flex items-center justify-between border-b-2 border-black ">
        <Title className="pb-2" variant="h2">
          {asText(slice.primary.title)}
        </Title>
      </div>
      <div className="mt-5">
        <FormUsePromotion isLoading={isLoading} onSubmit={onSubmit} />
      </div>
    </div>
  )
}

export default PromotionOffer
