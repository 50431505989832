import { IContactUsProps } from 'validators/contactUsValidation'
import { IPromotionValidator } from 'validators/promotionValidator'
import { publicClient } from 'utils'
import { getFromClient, setFromClient } from 'utils/cookies'
import { supportAddressConstant } from 'constants/email'

const contactUsLimitMessages = 5
const promotionOfferLimitMessages = 1

const postSendMessage = async (values: IContactUsProps) => {
  const sentMessages = Number(getFromClient('sent-messages-count')) ?? 0
  if (sentMessages >= contactUsLimitMessages) {
    throw Error('You can not sent message')
  }

  const { data } = await publicClient.post(`/mailing/send`, {
    sendTo: supportAddressConstant,
    subject: 'Contact Us',
    plainTextContent: `Name: ${values.name}\nSender: ${values.email}\nMessage: ${values.message}`,
    htmlContent: `Name: ${values.name}<br />Sender: ${values.email}<br />Message: ${values.message}`
  })
  setFromClient('sent-messages-count', (sentMessages + 1).toString())
  return data
}

const sendUsePromotionMail = async ({
  mailTo,
  name,
  email,
  phoneNumber
}: IPromotionValidator & { mailTo: string | null }) => {
  const sentMessages = Number(getFromClient('promotion-request-count')) ?? 0
  if (sentMessages >= promotionOfferLimitMessages) {
    throw Error('You can not sent message')
  }

  const { data } = await publicClient.post(`/mailing/send`, {
    sendTo: mailTo ?? supportAddressConstant,
    subject: 'Response to the offer',
    plainTextContent: `Name: ${name}\nSender: ${email}\nPhoneNumber: ${phoneNumber}`,
    htmlContent: `Name: ${name}<br />Sender: ${email}<br />PhoneNumber: ${phoneNumber}`
  })
  setFromClient('sent-messages-count', (sentMessages + 1).toString())
  return data
}

export { postSendMessage, sendUsePromotionMail }
