// This file is generated by Slice Machine. Update with care!

import AdvertCarousel from './AdvertCarousel'
import AdvertisementBanner from './AdvertisementBanner'
import AdvertsSearchSection from './AdvertsSearchSection'
import CommunityBullet from './CommunityBullet'
import CommunityContact from './CommunityContact'
import CommunityImage from './CommunityImage'
import CommunityMore from './CommunityMore'
import CommunityQoute from './CommunityQoute'
import CommunitySection from './CommunitySection'
import CommunityText from './CommunityText'
import CtaSection from './CtaSection'
import Hero from './Hero'
import HRizerButton from './HRizerButton'
import InfoSection from './InfoSection'
import InnerPricing from './InnerPricing'
import InteractiveMap from './InteractiveMap'
import MainFeatures from './MainFeatures'
import Newsletter from './Newsletter'
import NotFound from './NotFound'
import PricingPlan from './PricingPlan'
import PricingSection from './PricingSection'
import PromotionOffer from './PromotionOffer'
import SectionParagraph from './SectionParagraph'
import SectionTitle from './SectionTitle'
import SectionWithImage from './SectionWithImage'
import SectionWithImageAndLink from './SectionWithImageAndLink'
import Seo from './Seo'
import Sponsors from './Sponsors'
import TextList from './TextList'

export {
  AdvertCarousel,
  AdvertisementBanner,
  AdvertsSearchSection,
  CommunityBullet,
  CommunityContact,
  CommunityImage,
  CommunityMore,
  CommunityQoute,
  CommunitySection,
  CommunityText,
  CtaSection,
  Hero,
  HRizerButton,
  InfoSection,
  InnerPricing,
  InteractiveMap,
  MainFeatures,
  Newsletter,
  NotFound,
  PricingPlan,
  PricingSection,
  PromotionOffer,
  SectionParagraph,
  SectionTitle,
  SectionWithImage,
  SectionWithImageAndLink,
  Seo,
  Sponsors,
  TextList
}

export const components = {
  advert_carousel: AdvertCarousel,
  advertisement_banner: AdvertisementBanner,
  adverts_search_section: AdvertsSearchSection,
  community_bullet: CommunityBullet,
  community_contact: CommunityContact,
  community_image: CommunityImage,
  community_more: CommunityMore,
  community_qoute: CommunityQoute,
  community_section: CommunitySection,
  community_text: CommunityText,
  cta_section: CtaSection,
  h_rizer_button: HRizerButton,
  hero: Hero,
  info_section: InfoSection,
  inner_pricing: InnerPricing,
  interactive_map: InteractiveMap,
  main_features: MainFeatures,
  newsletter: Newsletter,
  not_found: NotFound,
  pricing_bullets: PricingPlan,
  pricing_section: PricingSection,
  promotion_offer: PromotionOffer,
  section_paragraph: SectionParagraph,
  section_title: SectionTitle,
  section_with_image: SectionWithImage,
  section_with_image_and_link: SectionWithImageAndLink,
  seo: Seo,
  sponsors: Sponsors,
  text_list: TextList
}
