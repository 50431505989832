import { asLink } from '@prismicio/helpers'
import clsx from 'clsx'

import BigLink from 'components/BigLink'

import { HRizerButtonSlice } from 'types/prismic'
import { linkResolver } from 'env/prismicio'

const HRizerButton = ({ slice }: { slice: HRizerButtonSlice }) => {
  return (
    <section>
      <div
        className={clsx(
          'my-10 flex',
          slice.primary.position === 'center' && 'justify-center',
          slice.primary.position === 'right' && 'justify-end'
        )}
      >
        <div className="px-5">
          <BigLink
            hrefDefault={asLink(slice.primary.link, linkResolver) ?? ''}
            buttonText={slice.primary.label}
          />
        </div>
      </div>
    </section>
  )
}

export default HRizerButton
