import { z } from 'zod'

const contactUsValidator = z.object({
  name: z.string().min(2).max(255),
  email: z.string().email(),
  message: z.string().min(5).max(700)
})

export type IContactUsProps = z.infer<typeof contactUsValidator>

export default contactUsValidator
