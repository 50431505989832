import { Bounded } from 'components/BoundedCommunity'
import FormContactUs from 'components/Forms/FormContactUs'

const ContactForm = () => {
  return (
    <Bounded as="section" size="small">
      <FormContactUs />
    </Bounded>
  )
}

export default ContactForm
