import Image from 'next/image'
import Link from 'next/link'
import { asLink } from '@prismicio/helpers'
import clsx from 'clsx'

import { Bounded, BoundedVariant } from 'components/Bounded'
import { IconArrow } from 'icons'

import { InfoSectionSlice, InfoSectionSliceDefaultItem } from 'types/prismic'
import { linkResolver } from 'env/prismicio'

const InfoSection = ({ slice }: { slice: InfoSectionSlice }) => {
  const link = (item?: InfoSectionSliceDefaultItem) => {
    const link = asLink(item?.link, linkResolver)
    const anchor = item?.anchorId ? `#${item.anchorId}` : ''
    return `${link}${anchor}` ?? ''
  }
  return (
    <Bounded
      as="section"
      variant={BoundedVariant.Base}
      collapsible={false}
      className="bg-white pb-10"
    >
      <div className="mb-10 flex w-full flex-col justify-between md:flex-row md:items-end">
        <div>
          <h3 className="text-2xl font-bold md:text-5xl">
            {slice.primary.title}
            <i className="dot"></i>
          </h3>
        </div>
      </div>
      <div>
        {slice.items.map((item, index) => {
          if (
            index % 4 === 0 ||
            (index % 4 === 0 && index === slice.items.length - 1)
          ) {
            return (
              <div
                key={item.title}
                className="mx-auto flex flex-col-reverse items-stretch gap-x-20 border-b-[2px] border-black pb-10 pt-10 md:flex-row md:border-r-[2px]"
              >
                <div className="mt-10 md:flex-1">
                  <h4 className="mb-5 text-2xl font-semibold md:text-3xl">
                    {item.title}
                  </h4>
                  <p className="mb-5 text-lg leading-normal text-gray-500">
                    {item.description}
                  </p>
                  {item.linkText && (
                    <Link
                      href={link(item)}
                      className="animated-svg mt-2 flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600"
                    >
                      <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
                        {item.linkText}
                      </span>
                      <IconArrow />
                    </Link>
                  )}
                </div>
                <div className="relative mx-auto h-60 w-60 sm:h-80 sm:w-80 md:flex-1">
                  <Image
                    src={item.img.url ?? ''}
                    alt={item.img.alt ?? '' + ' logo'}
                    style={{ objectFit: 'contain', objectPosition: 'center' }}
                    fill
                  />
                </div>
              </div>
            )
          }
          if (index % 4 === 3 || index === slice.items.length - 1) {
            return (
              <div
                key={item.title}
                className="mx-auto flex flex-col-reverse items-stretch gap-x-20 border-t-[2px] border-black pb-10 pt-10 md:flex-row-reverse md:border-l-[2px]"
              >
                <div className="mt-10 md:flex-1">
                  <h4 className="mb-5 text-2xl font-semibold md:text-3xl">
                    {item.title}
                  </h4>
                  <p className="mb-5 text-lg leading-normal text-gray-500">
                    {item.description}
                  </p>
                  {item.linkText && (
                    <Link
                      href={link(item)}
                      className="animated-svg mt-2 flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600"
                    >
                      <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
                        {item.linkText}
                      </span>
                      <IconArrow />
                    </Link>
                  )}
                </div>
                <div className="relative mx-auto h-60 w-60 sm:h-80 sm:w-80 md:flex-1">
                  <Image
                    src={item.img.url ?? ''}
                    alt={item.img.alt ?? '' + ' logo'}
                    style={{ objectFit: 'contain', objectPosition: 'center' }}
                    fill
                  />
                </div>
              </div>
            )
          }
          if (index % 4 === 1) {
            return (
              <div
                key={item.title}
                className="mx-auto flex flex-col md:flex-row"
              >
                <div
                  className={clsx(
                    'flex w-full flex-1 flex-col-reverse gap-x-20 border-black py-10 pb-10 md:flex-col md:pr-10',
                    'flex-col border-b-[2px] md:border-r-[1px] md:border-b-0'
                  )}
                >
                  <div className="mt-10 flex-1">
                    <h4 className="mb-5 text-2xl font-semibold md:text-3xl">
                      {item.title}
                    </h4>
                    <p className="mb-5 text-lg leading-normal text-gray-500">
                      {item.description}
                    </p>
                    {item.linkText && (
                      <Link
                        href={link(item)}
                        className="animated-svg mt-2 flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600"
                      >
                        <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
                          {item.linkText}
                        </span>
                        <IconArrow />
                      </Link>
                    )}
                  </div>
                  <div className="relative mx-auto h-60 w-60 sm:h-80 sm:w-80 md:w-full">
                    <Image
                      src={item.img.url ?? ''}
                      alt={item.img.alt ?? '' + ' logo'}
                      style={{ objectFit: 'contain', objectPosition: 'center' }}
                      fill
                    />
                  </div>
                </div>
                <div
                  className={clsx(
                    'flex w-full flex-1 flex-row-reverse gap-x-20 border-black py-10',
                    'flex-col-reverse md:flex-col md:border-l-[1px] md:pl-10'
                  )}
                >
                  <div className="mt-10 flex-1">
                    <h4 className="mb-5 text-2xl font-semibold md:text-3xl">
                      {slice.items[index + 1]?.title}
                    </h4>
                    <p className="mb-5 text-lg leading-normal text-gray-500">
                      {slice.items[index + 1]?.description}
                    </p>
                    {slice.items[index + 1]?.linkText && (
                      <Link
                        href={link(slice.items[index + 1])}
                        className="animated-svg mt-2 flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600"
                      >
                        <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
                          {slice.items[index + 1]?.linkText}
                        </span>
                        <IconArrow />
                      </Link>
                    )}
                  </div>
                  <div className="relative mx-auto h-60 w-60 sm:h-80 sm:w-80 md:w-full">
                    <Image
                      src={slice.items[index + 1]?.img.url ?? ''}
                      alt={slice.items[index + 1]?.img.alt ?? '' + ' logo'}
                      style={{ objectFit: 'contain', objectPosition: 'center' }}
                      fill
                    />
                  </div>
                </div>
              </div>
            )
          }
          return null
        })}
      </div>
    </Bounded>
  )
}

export default InfoSection
